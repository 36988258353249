import { GET, POST, PUT } from './requestClient';
import { ClientResponse } from '../interfaces';

export const SignInUser = (email: string, password: string): Promise<ClientResponse<any>> => {
  return POST('/api/login', { email, password });
};

export const SignUpUser = (coach: any): Promise<ClientResponse<any>> => {
  return POST('/api/signup', coach);
};

export const GetAllCoachees = (filterValues: any = {}): Promise<ClientResponse<any>> => {
  const params = new URLSearchParams(filterValues).toString();
  return GET(`/api/coachees?${params}`);
};

// This is the Client-Side API Request:
export const getInactiveFellows = (months: number): Promise<ClientResponse<any>> => {
  return GET(`/api/inactive-fellows?months=${months}`);
};


export const GetCurrentCoach = (filterValues: any = {}): Promise<ClientResponse<any>> => {
  return GET(`/api/current_coach`);
};

export const UpdateCoach = (calendly_api_token: string): Promise<ClientResponse<any>> => {
  return PUT('/api/coach/update', { calendly_api_token: calendly_api_token });
}

export const AddCoachee = (first_name: String, last_name: String, email: String, phone: String): Promise<ClientResponse<any>> => {
  return POST('/api/coachee/add', { first_name: first_name, last_name: last_name, email: email, phone: phone });
}

export const UpdateCoachee = (id: number, first_name: String, last_name: String, email: String, phone: String): Promise<ClientResponse<any>> => {
  const body = {
    id: id,
    first_name: first_name,
    last_name: last_name,
    email: email,
    phone: phone
  }

  return PUT('/api/coachee/update', body);
}

export const SendVoiceCall = (selectedCoachees: readonly number[], message: string): Promise<ClientResponse<any>> => {
  return POST('/api/voice-call', { coachee_ids: selectedCoachees, message: message });
}

export const SendSMS = (selectedCoachees: readonly number[], message: string): Promise<ClientResponse<any>> => {
  return POST('/api/sms', { coachee_ids: selectedCoachees, message: message });
}

// Function to send bulk SMS
export const sendBulkSMS = (numbers: string[], message: string): Promise<ClientResponse<any>> => {
  return POST('/api/send-sms', { numbers, message });
};

// Function to compose emails
export const composeEmails = (emails: string[], subject: string, body: string): Promise<ClientResponse<any>> => {
  return POST('/api/compose-email', { emails, subject, body });
};

// Function to show past SMS
export const showPastSMS = (): Promise<ClientResponse<any>> => {
  return GET('/api/past-sms');
};

// Function to show Calendly events
export const showCalendlyEvents = (): Promise<ClientResponse<any>> => {
  return GET('/api/calendly-events');
};